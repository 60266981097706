<template>
  <div>
    <v-row>
      <v-col>
        <!-- <v-alert
          v-if="this.$i18n.locale == 'ja' || this.$i18n.locale == 'en'"
          dismissible
          dense
          type="info"
          class="text-h5"
        >
          {{ $t("message.advertisement_3") }}<br>
          {{ $t("message.advertisement_4") }}<br>
          {{ $t("message.advertisement_5") }}
          <font
            color="red"
            class="text-h3"
          >
            {{ reversedLastday }}
          </font>
          {{ $t("message.advertisement_6") }}
          <br>
          <a
            href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=887339517&vc_url=https%3A%2F%2Fstore.jp.square-enix.com%2Fcategory%2F11%2FSEDL_1103.html"
            rel="nofollow"
            class="text-h4"
          ><img
            src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=887339517"
            height="1"
            width="0"
            border="0"
          >{{ $t("message.advertisement_2") }}</a>
        </v-alert> -->
        <v-alert
          v-if="this.$i18n.locale == 'ja'"
          dismissible
          dense
          type="info"
          class="text-h5"
        >
          <a
            href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=887339517&vc_url=https%3A%2F%2Fstore.jp.square-enix.com%2Fitem_list.html%23SERIES%3D11%26pointercat%3DSERIES"
            rel="nofollow"
          ><img
            src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=887339517"
            height="1"
            width="0"
            border="0"
          >FF14グッズはこちらから<br>(スクウェア・エニックス e-STORE)</a>
        </v-alert>

        <v-chip
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
          right
          to="/components/tool/tab-1"
          @click="alert = !alert"
        >
          {{ $t("message.how_to_register") }}
        </v-chip>
        <v-chip
          v-clipboard:copy="tag"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          dark
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
        >
          <v-icon left>
            mdi-content-copy
          </v-icon>
          {{ $t("message.copy_tag") }}
        </v-chip>
      </v-col>
    </v-row>
    <!-- sort-by="user_id_str" -->
    <!-- :sort-desc="sortDesc" -->
    <v-data-iterator
      sort-by="update_date"
      :sort-desc="sortDesc"
      :items="items"
      :search="search"
      :footer-props="{ 'items-per-page-options': [20, 30, 40]}"
      :items-per-page="20"
      :page="page"
      :options="options"
      @options="optionsSave"
      @update:page="scrollTop"
    >
      <template v-slot:header>
        <!-- <v-btn
          color="primary"
          right
          @click="alert = !alert"
        >
          {{ $t("message.how_to_register") }}
        </v-btn>
        <material-alert
          :value="alert"
          color="white"
          dark
          transition="scale-transition"
        >
          <a
            :href="sns.twitter"
            target="_blank"
          >
            {{ $t("message.how_to_register_1") }}
            <v-icon
              class="mr-1"
              small
              color="#55acee"
            >
              mdi-twitter
            </v-icon></a>
          <p>
            {{ $t("message.how_to_register_2") }}
            <v-icon
              class="mr-1"
              small
              color="#55acee"
            >
              mdi-twitter
            </v-icon>
          </p>
          <p>{{ $t("message.how_to_register_3") }}</p>
        </material-alert> -->

        <v-toolbar
          dark
          color="gray darken-3"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('message.search_housinger')"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                color="gray"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="gray"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-container
          id="user-profile-view"
          fluid
          tag="section"
        >
          <!-- v-touch="{
            left: () => $router.go(1),
            right: () => $router.push(-1)
          }" -->
          <v-row
            justify="center"
          >
            <v-col
              v-for="item in props.items"
              :key="item.user_id_str"
              cols="12"
              sm="6"
              md="4"
              lg="2"
            >
              <v-card
                class="mt-4 text-center"
                max-width="500"
              >
                <!-- <svg
                width="relative"
                height="20"
              >
                <circle
                  cx="3%"
                  cy="60%"
                  r="5"
                >new</circle></svg> -->

                <v-img
                  :src="item.user_profile_image_url_https_400x400"
                  height="250"
                />

                <v-chip
                  v-if="item.update_date >= targetDate"
                  outlined
                  color="white"
                  text-color="#e91e63"
                >
                  <v-icon>
                    mdi-alert-circle-outline
                  </v-icon>
                  UPDATE
                </v-chip>
                <v-card-text class="text-center">
                  <h6 class="text-h6 mb-2 text--secondary">
                    @{{ item.user_screen_name }}
                  </h6>

                  <h4 class="text-h4 mb-3 text--primary">
                    {{ item.user_name }}
                  </h4>

                  <p class="text--secondary">
                    {{ item.user_description }}
                  </p>
                  <router-link
                    :to="{ name: 'Artwork', params: { id: item.user_id_str } }"
                  >
                    <v-btn
                      class="mr-0"
                      color="primary"
                      min-width="100"
                      rounded
                    >
                      {{ $t("message.view_the_artwork") }}
                    </v-btn>
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          <!-- <ads /> -->
          </v-row>
        </v-container>
      </template>
    </v-data-iterator>
    <div />
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  // import Ads from './Ads'
  // import Spinner from 'vue-simple-spinner'
  export default {
    name: 'HousingersView',
    metaInfo: {
      title: 'House Designer',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    components: {
      // Spinner,
      // Ads,
    },
    data: function () {
      return {
        items: [],
        // selectedId: "",
        sortDesc: true,
        search: '',
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
        alert: false,
        options: {},
        page: 1,
        targetDate: '',
        tag: '#HousingEden',
      }
    },
    computed: {
      // 算出 getter 関数
      reversedLastday: function () {
        // `this` は vm インスタンスを指します
        const date = moment('2021-12-03 00:00')
        const today = moment().startOf('day')
        var days = Math.round(moment.duration(date - today).asDays())
        // console.log(date)
        // console.log(today)
        // console.log(days)
        return days
      },
    },
    watch: {
      items: function () {
        this.$nextTick(() => {
          var positionY = sessionStorage.getItem('positionY')
          setTimeout(function () {
            scrollTo(0, positionY)
          }, 500)
        })
      },
    },
    created: function () {
      // 日付をYYYY-MM-DDの書式で返すメソッド
      function formatDate (dt) {
        var y = dt.getFullYear()
        var m = ('00' + (dt.getMonth() + 1)).slice(-2)
        var d = ('00' + dt.getDate()).slice(-2)
        return (y + '-' + m + '-' + d)
      }
      this.targetDate = new Date()
      this.targetDate = formatDate(new Date(this.targetDate.getFullYear(), this.targetDate.getMonth(), this.targetDate.getDate() - 2))
      // console.log(this.targetDate)
      // console.log(this.$store.housingersItems)
      if (!this.$store.housingersItems) {
        axios.get(`../../housinger.json?timestamp=${new Date().getTime()}`).then(res => {
          this.items = res.data
          this.$store.housingersItems = res.data
          // res.data.forEach(data => {
          //   let inputdata;
          //   inputdata = data.quoted_status
          //   inputdata.user_name = data.quoted_status.user.name;
          //   inputdata.user_screen_name = data.quoted_status.user.screen_name;
          //   inputdata.user_user_id_str = data.quoted_status.user.user_id_str;
          //   this.items.push(inputdata);
          // });
        })
      } else { this.items = this.$store.housingersItems }

      if (this.$store.housingersOptions) {
        this.options = this.$store.housingersOptions
      }
      // console.log(this.$store.page)
      this.page = this.$store.housingersPage
      // console.log(this.$store.page)
    },
    methods: {
      scrollTop: function (event) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        // console.log(event)
        this.$store.housingersPage = event
      },
      optionsSave: function (event) {
        // console.log(event)
        this.$store.housingersOptions = event
      },
      onCopy: function (e) {
        alert('Copied')
        console.log('You just copied: ' + e.text)
      },
      onError: function (e) {
        console.log('Failed to copy texts')
      },
    },
  }
</script>

<style>
    circle {
      fill: #e91e63;
      /* stroke: #333; */
    }
</style>
